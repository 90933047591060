import _ from 'lodash';
import api from './base';
import { Carrier, EshopCarrier } from './apiTypes';
import { mockCarriers, mockEshopCarrier, mockEshopCarriers } from './mocks/carrierMock';

const ESHOP_CARRIERS_BASE_PATH = 'management/eshop-carriers';
const CARRIERS_BASE_PATH = 'management/carriers';

export async function getEshopCarriers(
  params: { eshopCode: string } | { apiGroup: string },
  signal?: AbortSignal,
): Promise<EshopCarrier[]> {
  return api
    .get<EshopCarrier[]>(`${ESHOP_CARRIERS_BASE_PATH}`, {
    params,
    signal,
  })
    .then(response => response.data);
}

api.mock
  .onGet('management/eshop-carriers')
  .reply(200, mockEshopCarriers);

export async function getEshopCarrier(
  id: number,
): Promise<EshopCarrier> {
  return api
    .get<EshopCarrier>(`${ESHOP_CARRIERS_BASE_PATH}/${id}`)
    .then(response => response.data);
}

api.mock
  .onGet(/management\/eshop-carriers\/.+/)
  .reply(200, mockEshopCarrier);

export async function postEshopCarrier(
  request: Partial<EshopCarrier>,
  signal?: AbortSignal,
): Promise<number> {
  return api
    .post<number>(`${ESHOP_CARRIERS_BASE_PATH}`, request, { signal })
    .then((response) => {
      const { location } = response.headers;
      const split = _.split(location, '/');
      const id = _.last(split);

      return +(id as string);
    });
}

api.mock
  .onPost('management/eshop-carriers')
  .reply(204, null, {
    Location: '/v1/management/eshop-carriers/1',
  });

export async function getCarriers(signal?: AbortSignal): Promise<Carrier[]> {
  return api
    .get<Carrier[]>(`${CARRIERS_BASE_PATH}`, { signal })
    .then((response) => response.data);
}

api.mock
  .onGet('management/carriers')
  .reply(200, mockCarriers);
