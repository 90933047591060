import React from 'react';
import { Divider, Panel, Stack } from 'rsuite';
import {
  createReportExistingProductMissingParameters,
  createReportNewProductMissingParameters,
  fullImportOffers,
  importOffersMissingParameters,
} from '../api/products';

import DownloadTemplate from '../components/DownloadTemplate';
import Guidelines from '../components/Guidelines';
import FileUploadCsv from '../components/FileUploadCsv';
import DownloadReport from '../components/DownloadReport';

interface Props {
  type: 'KAUFLAND' | 'ALLEGRO'
}

const templateFilenames = {
  KAUFLAND: 'templateKauflandOffers.csv',
  ALLEGRO: 'templateAllegroOffers.csv',
};

export default function ImportOffers({ type }: Props) {
  async function uploadFile(file:Blob, fileName: string) {
    const data = new FormData();
    data.append('attachment', file, fileName);
    const response = await fullImportOffers(data, type);
    return response.data;
  }

  async function uploadFileMissingParameters(file:Blob, fileName: string) {
    const data = new FormData();
    data.append('attachment', file, fileName);
    return importOffersMissingParameters(data);
  }

  return (
    <Panel>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Guidelines />
        <DownloadTemplate filename={templateFilenames[type]} />
        <DownloadReport reportName="Export unmatched products" getReport={createReportNewProductMissingParameters} />
        <DownloadReport reportName="Export existing offers with missing parameters" getReport={createReportExistingProductMissingParameters} />
      </Stack>
      <Divider />
      <h6>import</h6>
      <FileUploadCsv className="mt-5" getUploadResult={(f, fileName) => uploadFile(f, fileName)} />
      {type === 'ALLEGRO' && (
      <div>
        <Divider />
        <h6>Import missing parameters for offers</h6>
        <FileUploadCsv className="mt-5" getUploadResult={(f, fileName) => uploadFileMissingParameters(f, fileName)} />
      </div>
      )}
    </Panel>
  );
}
